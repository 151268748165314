import { useState } from 'react';
import * as Sentry from '@sentry/remix';

import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Separator,
  Tooltip,
  TooltipTrigger,
} from 'react-aria-components';
import type { MenuItemProps } from 'react-aria-components';

import { twMerge } from 'tailwind-merge';
import { Link, useFetcher, useLocation, useNavigate } from '@remix-run/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faChevronDown,
  faCircleCheck,
  faCircleInfo,
  faGear,
  faHouse,
  faPlus,
  faRightFromBracket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import type { action } from '~/root';
import { useRootLoaderData } from '~/root';

import { InsomniaLabelIcon } from '~/components/icons/insomnia-label';
import { InsomniaLogoIcon } from '~/components/icons/insomnia-logo';
import CreateAccountModal from '~/components/create-account-modal';
import { PrimaryNavLinkButton } from '~/components/primary-nav-link-button';
import { CreateOrgModal } from '~/components/create-organization-modal';
import { EnterpriseRoleEnum } from '~/generated-types';

function getInitial(name: string): string {
  return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
}

const ENTERPRISE_PREFIX = 'enterprise-coowner-';

function UserMenu() {
  const { user, profile, currentPlan, ownEnterprises = [], currentEnterpriseId } = useRootLoaderData();
  const location = useLocation();
  const navigate = useNavigate();

  const fetcher = useFetcher<typeof action>();

  const [openModal, setOpenModal] = useState(false);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);

  const currentEnterprise = ownEnterprises?.find((enterprise) => enterprise.id === currentEnterpriseId);

  const filteredEnterprises = ownEnterprises.filter((enterprise) => !enterprise.hide);

  const name = profile?.name || `${user?.firstName} ${user?.lastName}`;

  const handleLogout = async () => {
    Sentry.configureScope((scope) => scope.setUser(null));
    sessionStorage.removeItem('privateKey');
    sessionStorage.removeItem('loginKey');

    fetcher.submit(
      {
        action: 'logout',
      },
      {
        method: 'POST',
      },
    );
  };

  if (!user) {
    return null;
  }

  const handleEnterpriseChange = (enterpriseId: string) => {
    const enterprise = ownEnterprises?.find((enterprise) => enterprise.id === enterpriseId);
    fetcher.submit(
      {
        action: 'enterprise-change',
        enterpriseId,
        enterpriseRole: enterprise?.role || '',
      },
      {
        method: 'POST',
      },
    );
  };

  const showMenuOptions =
    !location.pathname.includes('app/subscribe') && !location.pathname.includes('subscription/past-due');
  const needsUpgrade = ['free', 'individual'].includes(currentPlan?.type || '');
  const isEnterpriseMember = currentPlan?.type === 'enterprise-member';

  const showEnterpriseIndicator =
    Boolean(currentEnterprise) &&
    currentEnterprise?.role !== EnterpriseRoleEnum.Owner &&
    currentEnterprise?.role !== EnterpriseRoleEnum.OwnAccount;

  const showEnterpriseControl =
    currentPlan?.type === 'enterprise' ||
    (Boolean(currentEnterprise) && currentEnterprise?.role !== EnterpriseRoleEnum.OwnAccount);

  return (
    <>
      <MenuTrigger>
        <Button
          aria-label="User Menu"
          className="pressed:bg-opacity-40 inline-flex cursor-default items-center justify-center rounded-full border border-white/20 bg-black bg-opacity-10 bg-clip-padding p-1 text-black outline-none transition-colors hover:bg-opacity-20 focus-visible:ring-2 focus-visible:ring-white/75"
        >
          <div className="flex gap-0 md:gap-2">
            {profile && profile.picture ? (
              <img src={profile.picture} alt="profile" className="h-[24px] rounded-full" />
            ) : (
              <div className="h-[24px] rounded-full">{getInitial(name)}</div>
            )}
            <div className="flex items-center">
              <p className="m-0 hidden text-[14px] md:flex">{name}</p>
              {showEnterpriseIndicator && (
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faBuilding} className="mx-1.5 h-4 w-4 text-[#374151]" />
                  <span className="max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
                    {currentEnterprise?.name}
                  </span>
                </div>
              )}
              <FontAwesomeIcon icon={faChevronDown} className="mx-1.5 h-3 w-3 text-[#00000066]" />
            </div>
          </div>
        </Button>
        <Popover
          placement="bottom end"
          className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 w-60 origin-top-left overflow-auto rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
        >
          <Menu
            className="outline-none"
            aria-label="User menu"
            onAction={(key) => {
              if (key === 'organizations') {
                navigate('/app/dashboard/organizations');
              }

              if (key === 'new-organization') {
                if (currentEnterprise?.role === EnterpriseRoleEnum.CoOwner) {
                  setShowCreateOrgModal(true);
                  return;
                }

                if (currentEnterprise?.role === EnterpriseRoleEnum.Billing) {
                  setOpenModal(true);
                  return;
                }
                if (isEnterpriseMember) {
                  setOpenModal(true);
                } else if (needsUpgrade) {
                  navigate('/app/landing-page');
                } else {
                  setShowCreateOrgModal(true);
                }
              }

              if (key === 'enterprise-control') {
                navigate('/app/enterprise');
              }

              if (key === 'account-settings') {
                navigate('/app/settings/profile');
              }

              if (key === 'logout') {
                handleLogout();
              }

              if (String(key).startsWith(ENTERPRISE_PREFIX)) {
                handleEnterpriseChange(String(key).replace(ENTERPRISE_PREFIX, ''));
              }
            }}
          >
            {showMenuOptions && (
              <ActionItem id="organizations" key="organizations">
                <FontAwesomeIcon icon={faUsers} className="h-4 w-4 text-[#374151]" />
                Your organizations
              </ActionItem>
            )}
            {showMenuOptions && (
              <ActionItem id="new-organization" key="new-organization">
                <FontAwesomeIcon icon={faPlus} className="h-4 w-4 text-[#374151]" />
                New organization
              </ActionItem>
            )}
            {showMenuOptions && showEnterpriseControl && (
              <>
                <Separator className="mx-3 my-1 h-[1px] bg-gray-300" />
                <ActionItem id="enterprise-control" key="enterprise-control">
                  <FontAwesomeIcon icon={faBuilding} className="h-4 w-4 text-[#374151]" />
                  Enterprise Controls
                </ActionItem>
              </>
            )}
            {showMenuOptions && <Separator className="mx-3 my-1 h-[1px] bg-gray-300" />}
            {showMenuOptions && (
              <ActionItem id="account-settings" key="account-settings">
                <FontAwesomeIcon icon={faGear} className="h-4 w-4 text-[#374151]" />
                Account Settings
              </ActionItem>
            )}
            <ActionItem id="logout" key="logout">
              <FontAwesomeIcon icon={faRightFromBracket} className="h-4 w-4 text-[#374151]" />
              Log out
            </ActionItem>
            {filteredEnterprises.length > 0 && <Separator className="mx-3 my-1 h-[1px] bg-gray-300" />}
            {filteredEnterprises.map((item) => {
              return (
                <ActionItem
                  id={`${ENTERPRISE_PREFIX}${item.id}`}
                  key={item.id}
                  textValue={`${ENTERPRISE_PREFIX}${item.name}`}
                >
                  <FontAwesomeIcon
                    icon={item.isOwnAccount ? faHouse : faBuilding}
                    className={`h-4 w-4 ${currentEnterpriseId === item.id ? 'text-primary' : 'text-[#374151]'} `}
                  />
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap">{item.name}</p>
                  {currentEnterpriseId === item.id && (
                    <FontAwesomeIcon icon={faCircleCheck} className="h-4 w-4 text-primary" />
                  )}
                </ActionItem>
              );
            })}
          </Menu>
        </Popover>
      </MenuTrigger>
      <CreateAccountModal open={openModal} onClose={() => setOpenModal(false)} />
      {showCreateOrgModal && <CreateOrgModal onClose={() => setShowCreateOrgModal(false)} />}
    </>
  );
}

function ActionItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      className="group box-border flex w-full cursor-default items-center gap-3 rounded-md px-3 py-2 text-gray-900 outline-none transition-colors duration-200 focus:bg-[#0000000a]"
    />
  );
}

function UpgradeButton() {
  const { currentPlan } = useRootLoaderData();
  const location = useLocation();

  // Hide this button in subscribe page and if user has enterprise plan selected.
  if (
    location.pathname.includes('subscribe') ||
    location.pathname.includes('subscription/update') ||
    location.pathname.includes('subscription/past-due')
  ) {
    return null;
  }

  // If user has a team or enterprise plan we navigate them to the Enterprise contact page.
  if (['team', 'enterprise'].includes(currentPlan?.type || '')) {
    return (
      <PrimaryNavLinkButton
        to="https://insomnia.rest/pricing/contact"
        target="__blank"
        className={twMerge(
          'px-14px h-[32px] text-white',
          currentPlan?.type === 'enterprise' ? 'w-[150px]' : 'w-[130px]',
        )}
      >
        {currentPlan?.type === 'enterprise' ? '+ Add more seats' : 'Upgrade'}
      </PrimaryNavLinkButton>
    );
  }

  return (
    <PrimaryNavLinkButton
      aria-label="Upgrade plan"
      to="/app/subscription/update?plan=team&payment_schedule=year"
      className="h-[32px] w-[130px] px-[14px] text-white"
    >
      Upgrade
    </PrimaryNavLinkButton>
  );
}

function CreateProjectInfo() {
  const location = useLocation();

  // Hide this button in subscribe page and if user has enterprise plan selected.
  if (
    location.pathname.includes('subscribe') ||
    location.pathname.includes('subscription/update') ||
    location.pathname.includes('subscription/past-due')
  ) {
    return null;
  }

  return (
    <TooltipTrigger delay={0}>
      <Button>
        <FontAwesomeIcon icon={faCircleInfo} className="h-6 w-6 fill-current text-purple-900" />
      </Button>
      <Tooltip
        offset={8}
        placement="bottom end"
        className="flex max-w-xs select-none flex-col overflow-y-auto rounded-md border border-solid border-gray-200 bg-white p-4 text-sm text-[#000000de] shadow focus:outline-none"
      >
        <div className="flex flex-col gap-2">
          <span className="text-[16px] font-normal leading-5 tracking-[-0.25px] text-gray-800">
            This is an enterprise corporate account.
          </span>
          <span className="mt-0.5 text-[14px] font-normal leading-5 tracking-[-0.25px] text-gray-800">
            As such, you may lose access to the account if you and the organization part ways.
          </span>
        </div>
      </Tooltip>
    </TooltipTrigger>
  );
}

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentPlan } = useRootLoaderData();

  const isEnterpriseMember = currentPlan?.type === 'enterprise-member';

  return (
    <div className="flex h-[60px] w-full justify-center border-b border-gray-300 bg-white">
      <div className="flex w-full max-w-[1300px] items-center justify-between p-3">
        <div className="flex items-center gap-20">
          <div className="flex cursor-pointer items-center" onClick={() => navigate('/app/dashboard/organizations')}>
            <InsomniaLogoIcon />
            <InsomniaLabelIcon />
          </div>

          <nav>
            <ul className="flex items-center gap-4">
              <li>
                <Link
                  to="/app/dashboard/organizations"
                  className={twMerge(
                    'rounded px-4 py-2 text-[14px] text-[#000000a6] hover:bg-[#0000000a] hover:no-underline',
                    location.pathname.includes('/app/') ? 'bg-[#0000000a]' : '',
                  )}
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/ai-runners"
                  className={twMerge(
                    'rounded px-4 py-2 text-[14px] text-[#000000a6] hover:bg-[#0000000a] hover:no-underline',
                    location.pathname.includes('ai-runners') ? 'bg-[#0000000a]' : '',
                  )}
                >
                  AI Runners
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flex items-center space-x-2">
          <UserMenu />
          {isEnterpriseMember ? <CreateProjectInfo /> : <UpgradeButton />}
        </div>
      </div>
    </div>
  );
}
